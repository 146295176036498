import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-notifications/lib/notifications.css';
import {
    Table,
    Button,
    Container
} from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const Download = props => {

    const { t } = useTranslation();

    const downloadURI = (uri, name) => {

        var link = document.createElement("a");
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', name);
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        link.remove();

    }

    return (
        <div
            style={{
                height: window.innerHeight - 48,
                backgroundColor: "#fafafa"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "10vh",
                    padding: "1em"
                }}
            >
                <Container>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th
                                    style={{ width: "10em" }}
                                >
                                    {t("File Name")}
                                </th>
                                <th>{t("Description")}</th>
                                <th
                                    style={{ width: "14em" }}
                                >
                                    {t("Operating System")}
                                </th>
                                <th
                                    style={{ width: "10em" }}
                                >
                                    {t("Latest Version")}
                                </th>
                                <th
                                    style={{ width: "8em" }}
                                >
                                    {t("Download")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>PostgreSQL</td>
                                <td>{t("pss")}</td>
                                <td>Windows x86-64</td>
                                <td>15.4</td>
                                <td>
                                    <Button
                                        onClick={() => window.open("https://www.enterprisedb.com/downloads/postgres-postgresql-downloads", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')}
                                    >
                                        <i className="bi bi-download"></i>
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>UsbTreeView</td>
                                <td>{t("utv")}</td>
                                <td>Windows x86-64</td>
                                <td>3.8.8</td>
                                <td>
                                    <Button
                                        onClick={() => window.open("https://www.uwe-sieber.de/usbtreeview_e.html", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')}
                                    >
                                        <i className="bi bi-download"></i>
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>curl</td>
                                <td>{t("curl")}</td>
                                <td>Windows x86-x64</td>
                                <td>8.2.1</td>
                                <td>
                                    <Button
                                        onClick={() => window.open("https://curl.se/download.html#Win64", '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')}
                                    >
                                        <i className="bi bi-download"></i>
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>Melonpos</td>
                                <td>{t("mps")}</td>
                                <td>Windows x64</td>
                                <td>0.0.4</td>
                                <td>
                                    <Button
                                        onClick={() => downloadURI("/download/melonpos_windows_v0.0.4.zip", "melonpos_windows_v0.0.4.zip")}
                                    >
                                        <i className="bi bi-download"></i>
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>Melontool</td>
                                <td>{t("mts")}</td>
                                <td>Windows x64</td>
                                <td>0.0.4</td>
                                <td>
                                    <Button
                                        onClick={() => downloadURI("/download/melontool_windows_v0.0.4.zip", "melontool_windows_v0.0.4.zip")}
                                    >
                                        <i className="bi bi-download"></i>
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>Shopeeple</td>
                                <td>{t("sps")}</td>
                                <td>Windows x64</td>
                                <td>0.0.2</td>
                                <td>
                                    <Button
                                        onClick={() => downloadURI("/download/shopeeple_v0.0.2.zip", "shopeeple_v0.0.2.zip")}
                                    >
                                        <i className="bi bi-download"></i>
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Container>
            </div>
        </div>
    );

}

export default Download;
