import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-notifications/lib/notifications.css';
import {
    Container,
    Image
} from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const PosInstall = props => {

    const { t } = useTranslation();
    return (
        <Container>
            <div
                style={{ margin: "1em" }}
            >
                {t("ipi1")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\posinstall\pi1.jpg"
            />
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\posinstall\pi2.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("ipi2")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\posinstall\pi3.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("ipi3")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\posinstall\pi4.jpg"
            />
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\posinstall\pi5.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("ipi4")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\posinstall\pi6.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("ipi5")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\posinstall\pi7.jpg"
            />
        </Container>
    );

}

export default PosInstall;
