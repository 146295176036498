import React, { useState } from 'react';
import {
    Navbar,
    Nav,
    Form,
    Image,
    Container
} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { language, languages, changeLanguage } from '../../i18n';

const logo = "/logo192.png";

const TopBar = props => {

    const [lang, setLang] = useState(() => language());
    const { t } = useTranslation();
    const { pageKey } = props;

    const setPageKey = (key) => {

        if (window.spa) {

            props.setPageKey(key);

        }

    }

    return (
        <Navbar
            bg="light"
            className='pos-topbar'
        >
            <Container
                style={{
                    position: "absolute",
                    left: 80
                }}
            >
                <Image
                    style={{
                        display: "block",
                        textAlign: 'center',
                        maxWidth: "80px",
                        maxHeight: "46px"
                    }}
                    src={logo}
                    role="button"
                    onClick={() => setPageKey("home")}
                />

            </Container>
            <Container
                style={{
                    position: "absolute",
                    left: 100
                }}
                role="button"
                onClick={() => setPageKey("home")}
            >
                Melonsoft
            </Container>
            <Container
                style={{
                    position: "absolute",
                    left: 220
                }}
            >
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
                    <Nav className="me-auto">
                        <Nav.Link
                            onClick={() => setPageKey("downloads")}
                            active={pageKey === "downloads"}
                        >
                            <i className="bi bi-card-image">{t("Download")}</i>
                        </Nav.Link>
                        <Nav.Link
                            onClick={() => setPageKey("installation")}
                            active={pageKey === "installation"}
                        >
                            <i className="bi bi-card-image">{t("Installation Guide")}</i>
                        </Nav.Link>
                        <Nav.Link
                            onClick={() => setPageKey("screenshots")}
                            active={pageKey === "screenshots"}
                        >
                            <i className="bi bi-card-image">{t("Screenshot")}</i>
                        </Nav.Link>
                        <Nav.Link
                            onClick={() => setPageKey("contact")}
                            active={pageKey === "contact"}
                        >
                            <i className="bi bi-person-lines-fill">{t("Contact Us")}</i>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
            <div
                style={{
                    position: "absolute",
                    right: "1em"
                }}
            >
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className="me-auto">
                            <Nav.Link className="bi bi-translate">
                            </Nav.Link>
                            <Nav.Link>
                                <Form.Select
                                    size="sm"
                                    value={lang}
                                    onChange={e => changeLanguage(e.target.value, (err) => {

                                        if (!err) {

                                            setLang(e.target.value)

                                        }

                                    })}
                                >
                                    {

                                        languages.map(ele =>
                                            <option
                                                value={ele.value}
                                                key={ele.value}
                                            >
                                                {ele.title}
                                            </option>
                                        )

                                    }
                                </Form.Select>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </div>
        </Navbar>
    );

}

export default TopBar;