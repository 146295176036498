import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-notifications/lib/notifications.css';
import {
    Container,
    Table
} from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const printers = [
    {
        device: "TM-T82III",
        maker: "Epson",
        category: "Receipt Printer",
        protocol: "ESCPOS",
        shipment: true,
        shipping: true,
        usb: "USB\\VID_04B8&PID_0E28\\5837424A0029260000"
    },
    {
        device: "XP-A160M",
        maker: "Xprinter",
        category: "Receipt Printer",
        protocol: "ESCPOS",
        shipment: true,
        shipping: true,
    },
    {
        device: "XP-80C",
        maker: "Xprinter",
        category: "Receipt Printer",
        protocol: "ESCPOS",
        shipment: true,
        shipping: true,
        usb: "USB\\VID_0483&PID_5743\\6&1EC5E748&0&3"
    },
    {
        device: "XP-460B",
        maker: "Xprinter",
        category: "Label Printer",
        protocol: "TSPL/EPL/ZPL",
        shipment: false,
        shipping: true,
    },
    {
        device: "XP-490B",
        maker: "Xprinter",
        category: "Label Printer",
        protocol: "TSPL/EPL/ZPL",
        shipment: false,
        shipping: true,
        usb: "USB\\VID_2D37&PID_41A6\\490BUW20A220171"
    },
    {
        device: "RP-50",
        maker: "Racers",
        category: "Label Printer",
        protocol: "EPL/ZPL",
        shipment: false,
        shipping: true,
        usb: "USB\\VID_0999&PID_0011\\001201000003"
    },
    {
        device: "",
        maker: "Brother",
        category: "Label Printer",
        protocol: "Unknown",
        shipment: false,
        shipping: false,
    }
];

const ShopeeCompatibility = props => {

    const { t } = useTranslation();
    return (
        <Container>
            <div
                style={{ margin: "1em" }}
            >
                {t("com1")}
            </div>
            <Table
                style={{ textAlign: "center" }}
                bordered
                hover
            >
                <thead>
                    <tr>
                        <th>
                            {t("Device")}
                        </th>
                        <th>
                            {t("Maker")}
                        </th>
                        <th>
                            {t("Category")}
                        </th>
                        <th>
                            {t("Protocol")}
                        </th>
                        <th>
                            {t("Excel")}
                        </th>
                        <th>
                            {t("PDF")}
                        </th>
                        <th>
                            {t("USB")}<br />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {

                        printers.map((ele, i) =>
                            <tr
                                key={i}
                            >
                                <td>
                                    {ele.device}
                                </td>
                                <td>
                                    {ele.maker}
                                </td>
                                <td>
                                    {t(ele.category)}
                                </td>
                                <td>
                                    {t(ele.protocol)}
                                </td>
                                <td>
                                    {ele.shipment && <i className="bi bi-check"></i>}
                                </td>
                                <td>
                                    {ele.shipping && <i className="bi bi-check"></i>}
                                </td>
                                <td>
                                    {ele.usb}
                                </td>
                            </tr>
                        )

                    }
                </tbody>
            </Table>
        </Container>
    );

}

export default ShopeeCompatibility;
