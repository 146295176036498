import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-notifications/lib/notifications.css';
import {
    Container,
    Table
} from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const printers = [
    {
        device: "TM-T82III",
        maker: "Epson",
        category: "Receipt Printer",
        protocol: "ESCPOS",
        receipt: true,
        invoice0: true,
        invoice1: true,
        sticker: false
    },
    {
        device: "XP-A160M",
        maker: "Xprinter",
        category: "Receipt Printer",
        protocol: "ESCPOS",
        receipt: true,
        invoice0: false,
        invoice1: true,
        sticker: false
    },
    {
        device: "XP-460B",
        maker: "Xprinter",
        category: "Label Printer",
        protocol: "TSPL/EPL/ZPL",
        receipt: false,
        invoice0: false,
        invoice1: false,
        sticker: true
    },
    {
        device: "RP-50",
        maker: "Racers",
        category: "Label Printer",
        protocol: "EPL/ZPL",
        receipt: false,
        invoice0: false,
        invoice1: false,
        sticker: true
    },
    {
        device: "",
        maker: "Brother",
        category: "Label Printer",
        protocol: "Unknown",
        receipt: false,
        invoice0: false,
        invoice1: false,
        sticker: false
    }
];

const Compatibility = props => {

    const { t } = useTranslation();
    return (
        <Container>
            <div
                style={{ margin: "1em" }}
            >
                {t("com1")}
            </div>
            <Table
                style={{ textAlign: "center" }}
                bordered
                hover
            >
                <thead>
                    <tr>
                        <th>
                            {t("Device")}
                        </th>
                        <th>
                            {t("Maker")}
                        </th>
                        <th>
                            {t("Category")}
                        </th>
                        <th>
                            {t("Protocol")}
                        </th>
                        <th>
                            {t("Receipt")}
                        </th>
                        <th>
                            {t("e-invoice")}<br />
                            {t("Render")}0
                        </th>
                        <th>
                            {t("e-invoice")}<br />
                            {t("Render")}1
                        </th>
                        <th>
                            {t("Sticker")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {

                        printers.map((ele, i) =>
                            <tr
                                key={i}
                            >
                                <td>
                                    {ele.device}
                                </td>
                                <td>
                                    {ele.maker}
                                </td>
                                <td>
                                    {t(ele.category)}
                                </td>
                                <td>
                                    {t(ele.protocol)}
                                </td>
                                <td>
                                    {ele.receipt && <i className="bi bi-check"></i>}
                                </td>
                                <td>
                                    {ele.invoice0 && <i className="bi bi-check"></i>}
                                </td>
                                <td>
                                    {ele.invoice1 && <i className="bi bi-check"></i>}
                                </td>
                                <td>
                                    {ele.sticker && <i className="bi bi-check"></i>}
                                </td>
                            </tr>
                        )

                    }
                </tbody>
            </Table>
        </Container>
    );

}

export default Compatibility;
