import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-notifications/lib/notifications.css';
import './index.css';
import { NotificationContainer } from 'react-notifications';
import TopBar from './containers/component/TopBar';
import Home from "./containers/page/Home/Home";
import Contact from './containers/page/Contact/Contact';
import Download from './containers/page/Download/Download';
import Screenshot from './containers/page/Screenshot/Screenshot';
import Installation from './containers/page/Installation/Installation';

const App = () => {

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [pageKey, setPageKey] = useState("home");

    const urlToPageKey = () => {

        let key = window.location.pathname.split("/")[1] || "home";
        setPageKey(key);

    }

    const handleResize = () => {

        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });

    };
    
    useEffect(() => {

        urlToPageKey();
        window.addEventListener('popstate', urlToPageKey);
        window.addEventListener('resize', handleResize);
        return (() => {
            
            window.removeEventListener('popstate', urlToPageKey);
            window.removeEventListener('resize', handleResize);
        
        });

    }, []);

    useEffect(() => {

        window.history.pushState({}, "", "/" + pageKey);

    }, [pageKey])

    return (
        <div
            className="App"
            id="outer-container"
        >
            <TopBar
                pageKey={pageKey}
                setPageKey={setPageKey}
            />
            <div
                className='pos-scope'
                id="inner-container"
            >
                {

                    pageKey === "home" &&
                    <Home />

                }
                {

                    pageKey === "contact" &&
                    <Contact />

                }
                {

                    pageKey === "downloads" &&
                    <Download />

                }
                {

                    pageKey === "screenshots" &&
                    <Screenshot />

                }
                {

                    pageKey === "installation" &&
                    <Installation />

                }
            </div>
            <NotificationContainer />
        </div>
    );

}

export default App;

