import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import {
    Card,
    Button,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const EmailModal = props => {

    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    const handleClose = () => setShow(false);

    const copyToClipboard = (text) => {

        navigator.clipboard.writeText(text).then(() => {

            NotificationManager.success(t("Email copied!"));

        }, () => {

            NotificationManager.error("error");

        });

    }

    return (
        <>
            <Card
                onClick={() => setShow(true)}
                role="button"
                bg='light'
            >
                <Card.Body>
                    <Row>
                        <Col xs={2}
                            style={{
                                fontSize: "2em",
                            }}
                        >
                            <i className="bi bi-envelope"></i>
                        </Col>
                        <Col xs={8}>
                            <Card.Title>{t("Email")}</Card.Title>
                            <Card.Text>{t("Chick here to reveal")}</Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Modal
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Here's my email")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card
                        bg="info"
                        text="white"
                    >
                        <Card.Body>
                            stupid0319@gmail.com
                            <Button
                                style={{ marginLeft: "1em" }}
                                variant='light'
                                onClick={() => copyToClipboard("stupid0319@gmail.com")}
                            >
                                {t("Copy")}
                            </Button>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}

export default EmailModal;
