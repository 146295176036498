import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-notifications/lib/notifications.css';
import {
    Carousel,
    Image,
    Row,
    Col,
    Button,
    Form
} from 'react-bootstrap';
import { language, languages, changeLanguage } from '../../../i18n';
import { useTranslation } from "react-i18next";

const Home = props => {

    const [lang, setLang] = useState(() => language());
    const { t } = useTranslation();
    return (
        <>
            <div
                style={{
                    height: window.innerHeight - 48,
                    backgroundColor: "#fafafa"
                }}
            >
                <Row>
                    <Col xs={1}></Col>
                    <Col xs={5}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "40vh",
                            }}
                        >
                            <Image
                                src="/logo192.png"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "10vh",
                                fontSize: "calc(10px + 5vmin)"
                            }}
                        >
                            Melon POS System
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "5vh",
                                fontSize: "calc(10px + 2vmin)"
                            }}
                        >
                            Easy, Powerful and Fast With React.js base ui.
                        </div>
                        <div
                            style={{
                                marginTop: "5vh",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "5vh",
                                fontSize: "calc(10px + 2vmin)"
                            }}
                        >
                            Version 0.0.4
                        </div>
                    </Col>
                    <Col xs={5}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "40vh",
                            }}
                        >
                            <Image
                                src="/logo192.png"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "10vh",
                                fontSize: "calc(10px + 5vmin)"
                            }}
                        >
                            Shopeeple
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "5vh",
                                fontSize: "calc(10px + 2vmin)"
                            }}
                        >
                            {t("sps")}
                        </div>
                        <div
                            style={{
                                marginTop: "5vh",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                minHeight: "5vh",
                                fontSize: "calc(10px + 2vmin)"
                            }}
                        >
                            Version 0.0.2
                        </div>
                    </Col>
                </Row>
            </div>
            <div
                style={{
                    height: window.innerHeight - 48,
                    marginTop: "5vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "calc(10px + 2vmin)"
                }}
            >
                <Carousel
                    variant="dark"
                    style={{
                        width: "90vw"
                    }}
                >
                    {

                        [
                            "screenshot/pos1.jpg",
                            "screenshot/pos2.jpg",
                            "screenshot/salesorder1.jpg",
                            "screenshot/salesorder2.jpg",
                            "screenshot/salesorder3.jpg",
                            "screenshot/salesorder4.jpg",
                            "screenshot/salesorder5.jpg"
                        ].map((ele, i) =>
                            <Carousel.Item
                                key={i}
                            >
                                <Image
                                    style={{
                                        width: "100%"
                                    }}
                                    src={ele}
                                />
                            </Carousel.Item>
                        )

                    }
                </Carousel>
            </div>
            <div
                style={{
                    backgroundColor: "#fafafa",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "calc(10px + 2vmin)"
                }}
            >
                <div
                    style={{
                        margin: "5vh",
                        width: "90vw"
                    }}
                >
                    <Row>
                        <Col xs={4}>
                            <Button
                                variant='info'
                            >
                                <i className="bi bi-twitter">Tweet</i>
                            </Button>
                            <Button
                                style={{ marginLeft: "1em" }}
                                variant='primary'
                                onClick={() => window.open("https://www.facebook.com/Melonsoft-106274669124469", "_blank")}
                            >
                                <i className="bi bi-facebook">Like</i>
                            </Button>
                        </Col>
                        <Col xs={4}>
                            <Form.Select
                                size="sm"
                                value={lang}
                                onChange={e => changeLanguage(e.target.value, (err) => {

                                    if (!err) {

                                        setLang(e.target.value)

                                    }

                                })}
                            >
                                {

                                    languages.map(ele =>
                                        <option
                                            value={ele.value}
                                            key={ele.value}
                                        >
                                            {ele.title}
                                        </option>
                                    )

                                }
                            </Form.Select>
                        </Col>
                        <Col xs={4}></Col>
                    </Row>
                    <div>
                        Powered by Melonsoft Co., Ltd.
                    </div>
                </div>
            </div>
        </>
    );

}

export default Home;
