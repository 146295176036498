import React, { useState, useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-notifications/lib/notifications.css';
import {
    Image,
    Modal,
    Row,
    Col,
    Button,
    ListGroup
} from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const ss = [
    { src: "/screenshot/pos1.jpg", title: "POS", tag: ["POS"] },
    { src: "/screenshot/pos2.jpg", title: "POS", tag: ["POS"] },
    { src: "/screenshot/pos3.jpg", title: "POS", tag: ["POS"] },
    { src: "/screenshot/pos4.jpg", title: "POS", tag: ["POS"] },
    { src: "/screenshot/label1.jpg", title: "label", tag: ["Label"] },
    { src: "/screenshot/label2.jpg", title: "label", tag: ["Label"] },
    { src: "/screenshot/label3.jpg", title: "label", tag: ["Label"] },
    { src: "/screenshot/salesorder1.jpg", title: "Sales Order", tag: ["Sales Order"] },
    { src: "/screenshot/salesorder2.jpg", title: "Sales Order", tag: ["Sales Order"] },
    { src: "/screenshot/salesorder3.jpg", title: "Sales Order", tag: ["Sales Order"] },
    { src: "/screenshot/salesorder4.jpg", title: "Sales Order", tag: ["Sales Order"] },
    { src: "/screenshot/salesorder5.jpg", title: "Sales Order", tag: ["Sales Order"] },
    { src: "/shopeeple/shopeeple10.jpg", title: "sps", tag: ["sps"] },
    { src: "/shopeeple/shopeeple12.jpg", title: "sps", tag: ["sps"] },
    { src: "/shopeeple/shopeeple01.jpg", title: "sps", tag: ["sps"] },
    { src: "/shopeeple/shopeeple02.jpg", title: "sps", tag: ["sps"] },
    { src: "/shopeeple/shopeeple03.jpg", title: "sps", tag: ["sps"] },
];

const ShowModal = props => {

    const { src, setSrc } = props;
    const handleClose = () => setSrc(undefined);

    return (
        <Modal
            show={src !== undefined}
            onHide={handleClose}
            dialogClassName='modal-80w'
        >
            <Modal.Body>
                <Image
                    role="button"
                    style={{ width: "100%" }}
                    src={src}
                    onClick={() => window.open(src, "_blank")}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

const ImageBox = props => {

    const { data, setSrc } = props;
    return (
        <Col xs={6}>
            <Image
                role="button"
                style={{ width: "100%", margin: "0.5em" }}
                src={data.src}
                onClick={() => setSrc(data.src)}
                thumbnail
            />
        </Col>
    );

}

const Screenshot = props => {

    const [tag, setTag] = useState(undefined);
    const [src, setSrc] = useState(undefined);
    const { t } = useTranslation();

    const tags = useMemo(() => {

        let _tags = new Set();
        ss.forEach(ele => {

            ele.tag.forEach(el => {

                _tags.add(el);

            })

        });
        return _tags;

    }, []);

    const filteredss = useMemo(() => {

        if (tag === undefined) {

            return ss;

        }
        return ss.filter(ele => ele.tag.findIndex(el => el === tag) !== -1);

    }, [tag]);

    return (
        <Row style={{ marginLeft: "0.2em", marginRight: "0.2em", paddingTop: "0.2em" }}>
            <Col
                xs={2}
                style={{
                    padding: "0.5em",
                    height: window.innerHeight - 60,
                    overflow: "auto"
                }}
            >
                <ListGroup>
                    <ListGroup.Item
                        action
                        active={tag === undefined}
                        onClick={() => setTag(undefined)}
                    >
                        {t("All")}
                    </ListGroup.Item>
                    {

                        [...tags].map(ele =>
                            <ListGroup.Item
                                action
                                key={ele}
                                active={tag === ele}
                                onClick={() => setTag(ele)}
                            >
                                {t(ele)}
                            </ListGroup.Item>)

                    }
                </ListGroup>
            </Col>
            <Col xs={10}
                style={{
                    paddingLeft: "1em",
                    paddingRight: "1em",
                    height: window.innerHeight - 60,
                    overflow: "auto"
                }}
            >
                <Row>
                    {

                        filteredss.map(ele =>
                            <ImageBox
                                key={ele.src}
                                data={ele}
                                setSrc={setSrc}
                            />
                        )

                    }
                </Row>
            </Col>
            <ShowModal
                src={src}
                setSrc={setSrc}
            />
        </Row>
    );

}

export default Screenshot;
