import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-notifications/lib/notifications.css';
import {
    Row,
    Col,
    ListGroup
} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Postgresql from './Postgresql';
import PosInstall from './PosInstall';
import PosUpdate from './PosUpdate';
import PrinterSetting from './PrinterSetting';
import CurlInstall from './CurlInstall';
import Compatibility from './Compatibility';
import ExhibitSetting from './ExhibitSetting';
import Twicode from './Twicode';
import ShopeeCompatibility from './ShopeeCompatibility';
import PackingSlip from './PackingSlip';
import ShippingSlip from './ShippingSlip';

const navs = [
    {
        title: "Melon POS System", children: [
            {
                title: "Install", children: [
                    { title: "Curl Install", pageKey: "curl" },
                    { title: "Postgresql Install", pageKey: "postgresql" },
                    { title: "Melonpos Install", pageKey: "posinstall" },
                ]
            },
            { title: "Melonpos Update", pageKey: "posupdate" },
            { title: "POS Exhibit", pageKey: "exhibit" },
            {
                title: "Printer", children: [
                    { title: "Setting", pageKey: "printersetting" },
                    { title: "Compatibility", pageKey: "cts" }
                ]
            },
            {
                title: "e-invoice", children: [
                    { title: "Alphanumeric Code", pageKey: "twicode" },
                ]
            },
        ]
    },
    {
        title: "sps", children: [
            { title: "Packing Slip", pageKey: "packingslip" },
            { title: "Shipping Label", pageKey: "shippingslip" },
            {
                title: "Printer", children: [
                    { title: "Compatibility", pageKey: "sc" }
                ]
            },
        ]
    },
];

const NavItem = props => {

    const [folded, setFolded] = useState(true);
    const { item, pageKey, setPageKey, level } = props;
    const { t } = useTranslation();
    return (
        <>
            <ListGroup.Item
                onClick={() => {

                    if (item.pageKey !== undefined) {

                        setPageKey(item.pageKey)

                    }
                    if (item.children !== undefined) {

                        setFolded(state => !state);

                    }

                }}
                active={pageKey === item.pageKey}
                action
            >
                <span
                    style={{ marginLeft: level * 15 }}
                >
                    {

                        item.children !== undefined &&
                        <i className={folded ? "bi bi-folder2" : "bi bi-folder2-open"} />

                    }
                    {

                        item.pageKey !== undefined &&
                        <i className="bi bi-file-richtext" />

                    }
                    {t(item.title)}
                </span>
            </ListGroup.Item>
            {

                item.children !== undefined && !folded &&
                item.children.map((ele, i) =>
                    <NavItem
                        key={i}
                        item={ele}
                        setPageKey={setPageKey}
                        pageKey={pageKey}
                        level={level + 1}
                    />
                )

            }
        </>
    )

}

const Installation = props => {

    const [pageKey, setPageKey] = useState("");
    return (
        <Row style={{ marginLeft: "0.2em", marginRight: "0.2em", paddingTop: "0.2em" }}>
            <Col xs={2}
                style={{
                    padding: "0.5em",
                    height: window.innerHeight - 60,
                    overflow: "auto"
                }}
            >
                <ListGroup>
                    {

                        navs.map((ele, i) =>
                            <NavItem
                                key={i}
                                item={ele}
                                setPageKey={setPageKey}
                                pageKey={pageKey}
                                level={0}
                            />
                        )

                    }
                </ListGroup>
            </Col>
            <Col
                xs={10}
                style={{
                    paddingLeft: "1em",
                    paddingRight: "1em",
                    height: window.innerHeight - 60,
                    overflow: "auto"
                }}
            >
                {

                    pageKey === "curl" &&
                    <CurlInstall />

                }
                {

                    pageKey === "postgresql" &&
                    <Postgresql />

                }
                {

                    pageKey === "posinstall" &&
                    <PosInstall />

                }
                {

                    pageKey === "posupdate" &&
                    <PosUpdate />

                }
                {

                    pageKey === "printersetting" &&
                    <PrinterSetting />

                }
                {

                    pageKey === "cts" &&
                    <Compatibility />

                }
                {

                    pageKey === "exhibit" &&
                    <ExhibitSetting />

                }
                {

                    pageKey === "twicode" &&
                    <Twicode />

                }
                {

                    pageKey === "sc" &&
                    <ShopeeCompatibility />

                }
                {

                    pageKey === "packingslip" &&
                    <PackingSlip />

                }
                {

                    pageKey === "shippingslip" &&
                    <ShippingSlip />

                }
            </Col>
        </Row>
    );

}

export default Installation;
