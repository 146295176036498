import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './en.json';
import tw from './tw.json';
import jp from './jp.json';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en,
            tw,
            jp
        },
        lng: localStorage.getItem("language") || "tw",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

let resources = i18n.options.resources;

export const languages = Object.keys(resources).map(ele => {

    return { value: ele, title: resources[ele].translation._lang };

});

export const changeLanguage = (lng, callback) => {

    return i18n.changeLanguage(lng, (err, t) => {

        if (!err) {

            localStorage.setItem("language", lng);

        }
        if (callback) {

            callback(err, t);

        }
        
    });

};

export const language = () => {

    return i18n.language;

};