import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-notifications/lib/notifications.css';
import {
    Container,
    Row,
    Col,
    Card
} from 'react-bootstrap';
import EmailModal from './EmailModal';

const Contact = props => {

    return (
        <Container
            style={{ padding: "1em" }}
        >
            <Row>
                <Col xs={4}>
                    <Card
                        onClick={() => window.open("https://www.facebook.com/Melonsoft-106274669124469", "_blank")}
                        role="button"
                        bg='light'
                    >
                        <Card.Body>
                            <Row>
                                <Col xs={2}
                                    style={{
                                        fontSize: "2em",
                                    }}
                                >
                                    <i className="bi bi-facebook"></i>
                                </Col>
                                <Col xs={8}>
                                    <Card.Title>Facebook</Card.Title>
                                    <Card.Text>like</Card.Text>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={4}>
                    <EmailModal />
                </Col>
            </Row>
        </Container>
    );

}

export default Contact;
