import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-notifications/lib/notifications.css';
import {
    Container,
    Image
} from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const ShippingSlip = props => {

    const { t } = useTranslation();
    return (
        <Container>
            <div
                style={{ margin: "1em" }}
            >
                {t("spss1")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="/shopeeple/shopeeple08.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("spss2")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="/shopeeple/shopeeple14.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("spps3")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="/shopeeple/shopeeple05.jpg"
            />
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="/shopeeple/shopeeple06.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("spps4")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="/shopeeple/shopeeple11.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("spss5")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="/shopeeple/shopeeple12.jpg"
            />
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="/shopeeple/shopeeple01.jpg"
            />
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="/shopeeple/shopeeple02.jpg"
            />
        </Container>
    );

}

export default ShippingSlip;
