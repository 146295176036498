import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-notifications/lib/notifications.css';
import {
    Container,
    Image
} from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const PrinterSetting = props => {

    const { t } = useTranslation();
    return (
        <Container>
            <div
                style={{ margin: "1em" }}
            >
                {t("prs1")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\printersetting\ps06.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("prs2")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }} S
                src="\printersetting\ps01.jpg"
            />
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\printersetting\ps02.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("prs3")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\printersetting\ps08.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("prs4")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\printersetting\ps05.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("prs5")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\printersetting\ps03.jpg"
            />
            <div
                style={{ margin: "1em" }}
            >
                {t("prs6")}
            </div>
            <Image
                style={{ width: "60%", marginTop: "0.2em" }}
                src="\printersetting\ps04.jpg"
            />
        </Container>
    );

}

export default PrinterSetting;
